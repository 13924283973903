import axios from 'axios';

import { msalInstance } from 'components/user/auth/authProvider';
import { Events } from 'types/events';
import tracker from 'utility/eventTracking';
//https://@tanstack/react-query.tanstack.com/guides/ssr

async function post(url: string, params: any) {
  try {
    const response = await axios.post(process.env.NEXT_PUBLIC_ENV_API_URL + url, params, {
      headers: {
        ['Accept']: 'application/json',
        ['Authorization']: 'Bearer ' + (await auth.getAccessToken()),
      },
    });
    return response.data;
  } catch (error: any) {
    tracker.trackEvent(Events.AXIOS_FETCH_ERROR, { url: url, error: error });
    throw new Error('Axios Unable to Fetch');
  }
}

async function postExternal(url: string, params: any) {
  try {
    const response = await axios.post(url, params, {
      headers: {
        ['Accept']: 'application/json',
        ['Authorization']: 'Bearer ' + (await auth.getAccessToken()),
      },
    });
    return response.data;
  } catch (error: any) {
    tracker.trackEvent(Events.AXIOS_FETCH_ERROR, { url: url, error: error });
    throw new Error('Axios Unable to Fetch');
  }
}

async function deleteRequest(url: string, params: any = {}) {
  try {
    const response = await axios.delete(process.env.NEXT_PUBLIC_ENV_API_URL + url, {
      headers: {
        ['Accept']: 'application/json',
        ['Authorization']: 'Bearer ' + (await auth.getAccessToken()),
      },
      data: params,
    });
    return response.data;
  } catch (error) {
    tracker.trackEvent(Events.AXIOS_FETCH_ERROR, { url: url, error: error });
    throw new Error('Axios Unable to Fetch');
  }
}

async function get(url: string, params = {}) {
  try {
    const response = await axios.get(process.env.NEXT_PUBLIC_ENV_API_URL + url, {
      params,
      headers: {
        ['Accept']: 'application/json',
        ['Authorization']: 'Bearer ' + (await auth.getAccessToken()),
      },
    });
    return response.data;
  } catch (error) {
    tracker.trackEvent(Events.AXIOS_FETCH_ERROR, { url: url, error: error });
    throw new Error('Axios Unable to Fetch');
  }
}

async function getUnauthenticated(url: string, params = {}) {
  try {
    const response = await axios.get(process.env.NEXT_PUBLIC_ENV_API_URL + url, {
      params,
      headers: {
        ['Accept']: 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    tracker.trackEvent(Events.AXIOS_FETCH_ERROR, { url: url, error: error });
    throw new Error('Axios Unable to Fetch');
  }
}

const getAccessToken = async () => {
  const account = msalInstance.getActiveAccount();
  let token = '';
  if (account != null) {
    await msalInstance
      .acquireTokenSilent({
        scopes: [`${process.env.NEXT_PUBLIC_B2C_TOKEN_REFRESH}`],
        account: account,
      })
      .then((response) => {
        token = response.accessToken;
      });
    return token;
  }
};

//We export this way to mock the functions in jest.
const apiFunctions = {
  get,
  post,
  postExternal,
  delete: deleteRequest,
  getUnauthenticated,
};

export const auth = {
  getAccessToken,
};

export default apiFunctions;
